import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {ContactPageComponent} from "./contactpage/contact-page.component";
import {PlansPageComponent} from "./planspage/plans-page.component";
import {PageNotFoundComponent} from "./page-not-found-component/page-not-found.component";
import {BasicPageComponent} from "./basicpage/basic-page.component";
import {DetailedPageComponent} from "./detailed-page/detailed-page.component";
import {InterestsPageComponent} from "./interests-page/interests-page.component";
import {SearchPageComponent} from "./search-page/search-page.component";
import {MoviePageComponent} from "./movie-page/movie-page.component";
import {MusicPageComponent} from "./music-page/music-page.component";
import {TravelPageComponent} from "./travel-page/travel-page.component";
import {PlansUpcomingPageComponent} from "./plans-upcoming-page/plans-upcoming-page.component";
import {PlansProjectsPageComponent} from "./plans-projects-page/plans-projects-page.component";
import {TvPageComponent} from "./tv-page/tv-page.component";


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },

  /* Pages about me and my search */
  { path: 'about/basic',      component: BasicPageComponent },
  { path: 'about/detailed',      component: DetailedPageComponent },
  { path: 'about/interests',      component: InterestsPageComponent },
  { path: 'about/searching',      component: SearchPageComponent },

  { path: 'about/tv',      component: TvPageComponent },
  { path: 'about/movies',      component: MoviePageComponent },
  { path: 'about/music',      component: MusicPageComponent },
  { path: 'about/travel',      component: TravelPageComponent },

  { path: 'plans/upcoming',      component: PlansUpcomingPageComponent },
  { path: 'plans/projects',      component: PlansProjectsPageComponent},


  /* Contact Page */
  { path: 'contact',      component: ContactPageComponent },

  /* Current plans and upcoming*/
  {
    path: 'plans',
    component: PlansPageComponent,
    data: { title: 'Current Plans' }
  },

  /* Catch all errors to the error page */
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
