import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { DetailComponent } from './detail/detail.component';
import { MovieComponent } from './movie/movie.component';
import { MusicComponent } from './music/music.component';
import { InterestComponent } from './interest/interest.component';
import { TravelComponent } from './travel/travel.component';
import { SportComponent } from './sport/sport.component';
import { PlanComponent } from './plan/plan.component';
import { PersonalComponent } from './personal/personal.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import { CardComponent } from './card/card.component';
import {MatCardModule} from "@angular/material/card";
import { MenuComponent } from './menu/menu.component';
import {MatMenuModule} from "@angular/material/menu";
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactPageComponent } from './contactpage/contact-page.component';
import { PlansPageComponent } from './planspage/plans-page.component';
import { PageNotFoundComponent } from './page-not-found-component/page-not-found.component';
import { FoodComponent } from './food/food.component';
import { BasicComponent } from './basic/basic.component';
import { BucketComponent } from './bucket/bucket.component';
import { BasicPageComponent } from './basicpage/basic-page.component';
import { DetailedPageComponent } from './detailed-page/detailed-page.component';
import { InterestsPageComponent } from './interests-page/interests-page.component';
import {MatListModule} from "@angular/material/list";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CarouselModule} from "ngx-bootstrap";
import { SearchPageComponent } from './search-page/search-page.component';
import { SearchComponent } from './search/search.component';
import { MoviePageComponent } from './movie-page/movie-page.component';
import { MusicPageComponent } from './music-page/music-page.component';
import { TravelPageComponent } from './travel-page/travel-page.component';
import { PlansUpcomingPageComponent } from './plans-upcoming-page/plans-upcoming-page.component';
import { PlansUpcomingComponent } from './plans-upcoming/plans-upcoming.component';
import { PlansProjectsPageComponent } from './plans-projects-page/plans-projects-page.component';
import { PlansProjectsComponent } from './plans-projects/plans-projects.component';
import { TvPageComponent } from './tv-page/tv-page.component';
import { TvComponent } from './tv/tv.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatIconModule} from "@angular/material/icon";
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    DetailComponent,
    MovieComponent,
    MusicComponent,
    InterestComponent,
    TravelComponent,
    SportComponent,
    PlanComponent,
    PersonalComponent,
    ToolbarComponent,
    CardComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    ContactPageComponent,
    PlansPageComponent,
    PageNotFoundComponent,
    FoodComponent,
    BasicComponent,
    BucketComponent,
    BasicPageComponent,
    DetailedPageComponent,
    InterestsPageComponent,
    SearchPageComponent,
    SearchComponent,
    MoviePageComponent,
    MusicPageComponent,
    TravelPageComponent,
    PlansUpcomingPageComponent,
    PlansUpcomingComponent,
    PlansProjectsPageComponent,
    PlansProjectsComponent,
    TvPageComponent,
    TvComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatListModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule,
    CarouselModule,
    MatRadioModule,
    MatIconModule,
    MatExpansionModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
