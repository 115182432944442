import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-contact',
  //templateUrl: './contact.component.html',
  templateUrl: './contact-simple.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  /* High level form tracking */
  displayForm = false;
  contactType;
  contactForm;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.contactForm = this.formBuilder.group({
      contactChecked: false,
      contactName : new FormControl('', [Validators.required]),
      contactEmail : new FormControl('', [Validators.required, Validators.email]),
      contactPhone : new FormControl('', [Validators.required]),
      contactMessage: new FormControl('', [Validators.required]),
    });
  }

  onSubmit(contactForm) {
    if(this.contactForm.checked === true){

    }

    console.log("Name:" + contactForm.contactName);
    console.log("Email:" + contactForm.contactEmail);
    console.log("Phone:" + contactForm.contactPhone);
    console.log("Message:" + contactForm.contactMessage);

  }

  showForm(){
    return this.displayForm;
  }

  requiredForm(){
    return this.contactType === 'login';
  }

  clearContact() {
  }

  getErrorMessage() {
    return this.contactForm.contactEmail.hasError('required') ? 'You must enter a value' :
      this.contactForm.contactEmail.hasError('email') ? 'Not a valid email' : '';
  }

  ngOnInit() {
  }

}
