import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailed-page',
  templateUrl: './detailed-page.component.html',
  styleUrls: ['./detailed-page.component.scss']
})
export class DetailedPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
