import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  // Gifs that will be later pulled from an API service. Gotta make that dynamic.
  gifs = ["https://media1.tenor.com/images/2c9ec222fb909caad79735a4a2c1520e/tenor.gif",
          "https://media1.tenor.com/images/fdc702b58243414ec6f6bb193db76f04/tenor.gif",
          "https://media1.tenor.com/images/fd47161fb2538d0713e1f3b7357892e8/tenor.gif",
          "https://media1.tenor.com/images/963335750fa5528199e0ad4559b6e103/tenor.gif",
          "https://media1.tenor.com/images/a9a498ac40f5a940f838587eb9d26e89/tenor.gif",
          "https://media1.tenor.com/images/bc534dfc23d8a4f5d0d766fa697a3157/tenor.gif"];

  getRandomGifUrl(){
    return this.gifs[Math.floor(Math.random()*this.gifs.length)];
  }

  ngOnInit() {
  }

}
