import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plans-upcoming',
  templateUrl: './plans-upcoming.component.html',
  styleUrls: ['./plans-upcoming.component.scss']
})
export class PlansUpcomingComponent implements OnInit {
  panelOpenState;

  constructor() { }

  ngOnInit() {
  }

}
